<template>
  <div>

    <div id="main" class="d-flex align-center justify-center">
        <v-card class="border" width="800" tile color="transparent" flat>
           <h3 class="text-center   font-weight-black white--text">
            Manage All Business Things In One Account
          </h3>
          <h3 class="text-center font-weight-lighter white--text px-6 px-md-12 ">
            Etousoft aims to help businesses grow through quality and relevant software
          </h3>
          <div class="text-center" v-if="model"  >
            <v-btn id="btn" @click="Signin" large min-width="250" class="white--text mt-10" outlined rounded >Sign in </v-btn >
          </div>
        </v-card>
    </div>
    <v-container class="">
      <h2 class="mt-3">Just sign in and go</h2>
      <p class="font-weight-normal">
        Access your favorite Etousoft products and services with just one login. One username and
        password connects you to the files, photos, people, and content you care
        about most.
      </p>
      <ProductsDescription v-bind:info="info"></ProductsDescription>
     </v-container>
  </div>
</template>
<script>
import ProductsDescription from "../components/Product/productspartial";

export default {
  props: ['myInfo'],
  name: "Home",
  components: {
    ProductsDescription,
  },
  data: () => ({
    info: null,
    model : true
  }),
  created()
  {
    if(this.$user.SuccessLogin() )
    {
      this.info = this.myInfo
      this.model = false
    }
  },
  watch:
  {
    myInfo: function(val)
    {
      if(val)
      {
        this.info = val
      }
    }
  },
  methods: {
    check()
    {
      if(this.myInfo)
      {
        this.info = this.myInfo
        this.model = false
      }
    },
    Signin(){
        window.location.href = this.$config.login
    },
    Register(){
         window.location.href = this.$config.register
    }
  },
 
};
</script>

<style scoped>
#btn {
  text-transform: none;
  text-decoration: none;
}
#learn {
  background-image: url("../assets/strategy.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
#main {
  background-image: linear-gradient(
      to bottom,
      rgba(2, 0, 36, 0.8),
      rgba(0, 146, 155, 0.3)
    ),
  url("../assets/Svg/landingpage.svg");
  height: 420px;
  background-position: center;
  background-size: cover;
  color: white;
  padding: 20px;
}
</style>
